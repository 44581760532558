/* line 13, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.m-agent-coverage {
	text-align: justify;
}

/* line 17, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.agentdetails--wrapper {
	margin-right: 0;
	background-color: rgb(var(--color-global-primary));
	overflow: hidden;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .agentdetails--wrapper {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agentdetails--wrapper {
		margin-right: 0;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 17, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
	.agentdetails--wrapper {
		max-height: none;
	}
}

/* line 26, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.agentdetails--wrapper .c-paging {
	margin-bottom: 0;
	padding: 5px 20px 20px;
	background-color: rgb(var(--color-global-positive));
}

@media only screen and (max-width: 47.999em) {
	/* line 33, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
	.agentdetails--wrapper .agentdetails__logo {
		padding-left: 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agentdetails--wrapper .agentdetails__logo {
		padding-left: initial;
		padding-right: 20px;
	}
}

/* line 39, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.pb-preview__container .agentdetails--wrapper {
	height: auto;
	max-height: none;
}

/* line 44, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.agentdetails--wrapper .agent-logo.has-nophoto {
	display: none;
}

/* line 49, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.agentdetails__grid--wrapper {
	height: 100%;
}

@media only screen and (min-width: 60em) {
	/* line 53, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
	.agentprofile--container {
		padding: 20px 20px 0 40px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agentprofile--container {
		padding: 20px 40px 0 20px;
	}
}

/* line 58, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
.agentprofile--container .agentprofile {
	padding-left: 20px;
	padding-right: 20px;
}

@media only screen and (min-width: 120em) {
	/* line 65, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
	.agentdetails--narrow-container {
		left: 50%;
		transform: translateX(-50%);
		max-width: 1280px;
		position: relative;
	}
	/* line 71, scss/80-themes/Saunders/70-modules/agentdetails/module.scss */
	.agentdetails--narrow-container .agentprofile--container {
		padding-left: 0;
		padding-right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agentdetails--narrow-container .agentprofile--container {
		padding-left: initial;
		padding-right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .agentdetails--narrow-container .agentprofile--container {
		padding-right: initial;
		padding-left: 0;
	}
}

/*# sourceMappingURL=../../../../../true */